import React from "react";
import {
  Container,
  DisplaySection,
  PrintHTML,
  SecondSlider,
} from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import Pagination from "../../Tools/Pagination";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
const Launches = ({ name }) => {
  const { data: dataTrending, isLoading: isLoadingTrending } = useFETCH(
    "coming-soon-items?trending=1&local=" + localStorage.getItem("language")
  );
  const { data, isLoading } = useFETCH(
    "coming-soon-items?trending=0&local=" + localStorage.getItem("language")
  );
  const { content, dataTextAll } = useContextTranslate();
  return (
    <div className="relative">
      {/* <img src={image} alt="" className="absolute top-1/2 -translate-y-1/2" /> */}
      <SecondSlider name={name} />
      <section className="py-3">
        <Container>
          <h1 className="text-4xl text-center font-bold">{content.Launches}</h1>
          <h1 className="text-3xl my-4">
            <span className="text-Main mx-3 font-bold">
              {content.EXPLORENEWPROJECTS}
            </span>
            {content.DONTMISSOUT}
          </h1>

          <Pagination
            pageCount={Math.ceil(
              data?.data.data.total / data?.data.data.per_page
            )}
          >
            <Row>
              {isLoadingTrending ? (
                <>
                  <LoadingPro />
                  <LoadingPro />
                  <LoadingPro />
                </>
              ) : (
                data?.data.data.data.map((e) => (
                  <Col lg={4} sm={6}>
                    <DisplaySection
                      src={fileUrl + e.image}
                      name={e.name}
                      text="Price Start From "
                      history={e.price_start_from}
                      display="true"
                    />
                  </Col>
                ))
              )}
            </Row>
          </Pagination>
          <h1 className="text-4xl my-3 text-center font-bold">
            {content.TrendingProjects}
          </h1>
          <Pagination
            pageCount={Math.ceil(
              dataTrending?.data.data.total / dataTrending?.data.data.per_page
            )}
          >
            <Row>
              {isLoading ? (
                <>
                  <LoadingPro />
                  <LoadingPro />
                  <LoadingPro />
                </>
              ) : (
                dataTrending?.data.data.data.map((e) => (
                  <Col lg={4} sm={6}>
                    <DisplaySection
                      src={fileUrl + e.image}
                      name={e.name}
                      text="Price Start From "
                      history={e.price_start_from}
                      display="true"
                    />
                  </Col>
                ))
              )}
            </Row>
          </Pagination>
          <Container>
            <div className="bg-[#FAFBFF] rounded-xl p-5">
              <p className="text-red-600 font-bold text-2xl mb-2">{content?.RealEstate}</p>
              <PrintHTML text={dataTextAll?.description_launching} />
            </div>
          </Container>
        </Container>
      </section>
    </div>
  );
};

export default Launches;

const LoadingPro = () => {
  return (
    <Col lg={4} sm={6}>
      <div className="w-full h-[360px]  rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
        <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 "></div>
        <div className="bg-slate-300 animate-pulse w-3/5 h-5 my-2 "></div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
      </div>
    </Col>
  );
};
