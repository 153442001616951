function NormalPhoto({ src, name, title, title_job }) {
  return (
    <div className="bg-white p-2 h-full border rounded-3xl overflow-hidden space-y-3 transition-all hover:-translate-y-2 hover:shadow-md">
      <img
        src={src}
        alt=""
        className="w-full rounded-3xl px-2 my-2 h-[200px]"
      />
      <h2 className="text-center text-Main font-bold text-xl leading-8 bgImg">
        {name}
      </h2>
      <p className="text-center text-lg font-medium leading-8 bgImg">{title}</p>
      <p className="text-center text-lg font-medium leading-8 bgImg">
        {title_job}
      </p>
    </div>
  );
}
export default NormalPhoto;
