import React from "react";

const Container = ({ children, className, pp }) => {
  return (
    <section className={`py-5`}>
      <div className={`w-[95%] mx-auto ${className}`}>{children}</div>
    </section>
  );
};

export default Container;
