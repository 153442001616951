import React from "react";
import { Container } from "../../Tools/Grid-system";
import { PrintHTML, SecondSlider } from "../../components";
import { useContextTranslate } from "../../components/Translate/ContextProvider";

const TermsOfUse = () => {
  const { content, dataTextAll } = useContextTranslate();
  return (
    <>
      <SecondSlider name={content?.TermsOfUse} />
      <section className="py-3">
        <Container>
          <h1 className="text-4xl text-center font-bold">
            {content.TermsOfUse}
          </h1>
          <h1 className="text-4xl text-center font-bold my-4">
            {content.TermsAndConditions}
          </h1>
          <p className=" ">
            <PrintHTML text={dataTextAll?.terms_and_conditions} />
          </p>
          <h1 className="text-4xl text-center font-bold my-4">
            {content.AcceptableUse}
          </h1>
          <p className="">
            <PrintHTML text={dataTextAll?.acceptable_use} />
          </p>
          <h1 className="text-4xl text-center font-bold my-4">
            {content.AutomatedQueries}
          </h1>
          <p className="">
            <PrintHTML text={dataTextAll?.automated_queries} />
          </p>
        </Container>
      </section>
    </>
  );
};

export default TermsOfUse;
