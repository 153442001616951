import { Link } from "react-router-dom";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Container, SmallSection, Title } from "../../components";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import image from "../../images/Layer_x0020_1 (1).png";
function TopAreas() {
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `areas?local=${localStorage.getItem("language")}&trending=1`
  );
  return (
    <div className="relative">
      <Container>
        <img src={image} alt="" className="absolute -z-10 top-0 left-0" />
        <Row className="items-center">
          <Col col={8}>
            <Title title={content.TopAreas} />
          </Col>
          <Col
            col={4}
            className="text-end font-semibold text-xl underline hover:text-Main"
          >
            <Link to="/areas">{content.viewAll}</Link>
          </Col>
        </Row>
        <Row>
          {isLoading ? (
            <>
              <LoadingPro />
              <LoadingPro />
              <LoadingPro />
            </>
          ) : (
            data?.data.data.data.map((e) => (
              <Col lg={4} sm={6}>
                <SmallSection
                  src={fileUrl + e.image}
                  link={
                    "/home/area/" +
                    e.id +
                    "?area_id=" +
                    e.id +
                    "&local=" +
                    localStorage.getItem("language")
                  }
                  name={e.name}
                  compounds={e.total_compounds + " compounds"}
                  propertiesAvailable={
                    e.total_properties + " properties available"
                  }
                />
              </Col>
            ))
          )}
        </Row>
      </Container>
    </div>
  );
}
export default TopAreas;

const LoadingPro = () => {
  return (
    <Col lg={4} xs={6}>
      <div className="w-full h-[360px]  rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
        <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 "></div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
      </div>
    </Col>
  );
};
