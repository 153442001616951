import { Col, Container, Row } from "../../../Tools/Grid-system";
import { fileUrl, useFilter } from "../../../Tools/APIs";
import { useContextTranslate } from "../../../components/Translate/ContextProvider";

function SectionThree({ data, chlidren, name }) {
  const { content } = useContextTranslate();
  const { handleParamsClick, searchParams, handleParamsDelete } = useFilter();
  return (
    <div>
      <Container className="!py-0">
      <div className="flex items-center bg-slate-300 flex-wrap">
        {data?.property_types.map((e) => (
          <div
            onClick={() => {
              if (
                searchParams.has("property_type") &&
                +searchParams.get("property_type") === e.id
              ) {
                handleParamsDelete("property_type");
              } else {
                handleParamsClick("property_type", e.id);
              }
            }}
            className={`${
              +searchParams.get("property_type") === e.id
                ? "bg-white rounded-xl m-1"
                : ""
            } flex items-center justify-center hover:bg-white hover:rounded-xl border-2 border-Main p-1 rounded-xl transition-all py-2 px-5 cursor-pointer`}
          >
            <div className="flex justify-center gap-3 text-center font-semibold h-full">
              <img
                src={fileUrl + e.icon}
                alt=""
                width={40}
                height={40}
                className="mx-auto"
              />
              <div>
                <div className="min-h-[24px]">{e.name}</div>
                <div>{e.total}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
      </Container>
      <div>{chlidren}</div>
    </div>
  );
}
export default SectionThree;
