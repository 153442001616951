import { Col, Row } from "../../Tools/Grid-system";
import { Container, Product, SecondSlider } from "../../components";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import Pagination from "../../Tools/Pagination";
import { useContextTranslate } from "../../components/Translate/ContextProvider";

function Favorite() {
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `favorite?local=` + window.localStorage.getItem("language")
  );
  return (
    <>
      <SecondSlider name={content?.Favorite} />
      <section className="py-3">
        <Container>
          <h1 className="text-4xl text-center font-bold">{content.Favorite}</h1>
          <Pagination
            pageCount={Math.ceil(
              data?.data.data.total / data?.data.data.per_page
            )}
          >
            <Row>
              {isLoading ? (
                <>
                  <LoadingPro />
                  <LoadingPro />
                  <LoadingPro />
                  <LoadingPro />
                  <LoadingPro />
                  <LoadingPro />
                </>
              ) : (
                data?.data.data.data.map((e) => (
                  <Col lg={4} xs={6}>
                    <Product
                      id={e.id}
                      NewProperty=""
                      details="asd"
                      title={e.name}
                      address={e.address}
                      room={e.rooms}
                      houseArea={e.house_area}
                      image={fileUrl + e.image.file}
                      bathroom={e.bathroom}
                      whatsapp={e.whatsapp}
                      phone={e.phone_number}
                      price={e.price}
                      isFavorite={e.is_favorite}
                    />
                  </Col>
                ))
              )}
            </Row>
          </Pagination>
        </Container>
      </section>
    </>
  );
}
export default Favorite;

const LoadingPro = () => {
  return (
    <Col lg={4} xs={6}>
      <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
        <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 "></div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
        <div className="flex gap-5">
          <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
          <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
          <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
        </div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
      </div>
    </Col>
  );
};
