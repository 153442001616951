import React from "react";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Button, SecondSlider } from "../../components";
import Pagination from "../../Tools/Pagination";
import { BsTelephone } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { useContextTranslate } from "../../components/Translate/ContextProvider";

const Compounds = () => {
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `compounds?local=` + localStorage.getItem("language")
  );
  return (
    <>
      <SecondSlider name={content?.Compound} />
      <div className="flex items-center bg-slate-300 flex-wrap"></div>
      <Container>
        <Pagination
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
        >
          <Row>
            {isLoading ? (
              <>
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
              </>
            ) : (
              data?.data.data.data.map((e) => (
                <Col lg={4} xs={6}>
                  <CompoundsCard
                    id={e.id}
                    NewProperty=""
                    details="asd"
                    dataTypes={e.property_types}
                    title={e.name}
                    address={e.address}
                    houseArea={e.house_area}
                    image={fileUrl + e.main_image?.file}
                    whatsapp={e.whatsapp}
                    phone={e.phone_number}
                    start_price={e.start_price}
                    end_price={e.end_price}
                  />
                </Col>
              ))
            )}
          </Row>
        </Pagination>
      </Container>
    </>
  );
};

export default Compounds;
const LoadingPro = () => {
  return (
    <Col lg={4} xs={6}>
      <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
        <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 "></div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
        <div className="flex gap-5">
          <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
          <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
          <div className="bg-slate-300 animate-pulse w-8 h-8 my-2 rounded-full"></div>
        </div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
      </div>
    </Col>
  );
};
const CompoundsCard = ({
  title,
  start_price,
  end_price,
  image,
  address,
  whatsapp,
  phone,
  id,
  dataTypes,
}) => {
  return (
    <>
      <div
        className={
          "h-ful border bg-white mx-1 relative my-2 p-2 rounded-3xl transition-all hover:-translate-y-2 shadow-md hover:shadow-xl"
        }
      >
        <img src={image} alt="" className="w-full h-[250px] rounded-3xl" />
        <Row className="items-end">
          <Col col={10}>
            <p className="text-xl font-bold mb-1">{title}</p>
            <p className="text-lg text-black/50">{address}</p>
            <div className="py-2 flex items-center px-2 text-sm gap-2">
              {dataTypes?.slice(0, 3).map((e, i) => (
                <>
                  <div className=" flex  items-center !px-0">
                    <p className="font-bold text-Main">{e.total}</p>
                    <p>
                      {e.name} {i + 1 < dataTypes.length ? "|" : ""}
                    </p>
                  </div>
                </>
              ))}
              {dataTypes.length > 3 ? (
                <Col col={3} className="flex  items-center ">
                  <p className="text-sm font-bold text-Main">
                    {dataTypes.length}
                  </p>
                  <p>آخرى |</p>
                </Col>
              ) : (
                ""
              )}
            </div>
            <p className="text-sm font-semibold m">start price {start_price}</p>
            <p className="text-lg font-semibold mb-2">to {end_price}</p>
          </Col>
          <Col col={2} className="justify-end px-2">
            <a href={"tel:" + phone}>
              <div className="flex justify-center items-center w-[35px] h-[35px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full mb-3">
                <BsTelephone color="#062371" size={25} />
              </div>
            </a>
            <a href={"whatsapp://send?phone=" + whatsapp}>
              <div className="flex justify-center items-center w-[35px] h-[35px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full">
                <FaWhatsapp color="#062371" size={25} />
              </div>
            </a>
          </Col>
        </Row>

        <Button
          link={
            "/home/compound/" +
            id +
            "?compound_id=" +
            id +
            "&local=" +
            localStorage.getItem("language")
          }
          title="See Details"
          className="mt-5"
        />
      </div>
    </>
  );
};
