import React from "react";
import { ContactSection, Container, PrintHTML, SecondSlider } from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
const HowItWorks = () => {
  const { content, dataTextAll, LoadingText } = useContextTranslate();
  return (
    <>
      <SecondSlider name={content?.HowItWorks} />
      <section className="py-3">
        <h1 className="text-4xl text-center font-bold my-4">
          {content.HowItWorks}
        </h1>
        <Container>
          <Row className="justify-center">
            <Col md={10}>
              <h1 className="text-4xl text-center font-bold my-4">
                {content.Buyer}
              </h1>
              <div className="rounded-xl bg-[#FAFBFF] p-5">
                {LoadingText && (
                  <div className="space-y-5">
                    <div className="bg-gray-200 animate-pulse w-full h-8 my-2 "></div>
                    <div className="bg-gray-200 animate-pulse w-4/5 h-8 my-2 "></div>
                    <div className="bg-gray-200 animate-pulse w-2/3 h-8 my-2 "></div>
                    <div className="bg-gray-200 animate-pulse w-full h-8 my-2 "></div>
                    <div className="bg-gray-200 animate-pulse w-2/3 h-8 my-2 "></div>
                    <div className="bg-gray-200 animate-pulse w-[90%] h-8 my-2 "></div>
                  </div>
                )}
                <PrintHTML text={dataTextAll?.description_buyer} />
              </div>
            </Col>
            <Col md={10}>
              <h1 className="text-4xl text-center font-bold my-4">
                {content.Seller}
              </h1>
              <div className="rounded-xl bg-[#FEF5F5] p-5">
                {LoadingText && (
                  <div className="space-y-5">
                    <div className="bg-gray-100 animate-pulse w-full h-8 my-2 "></div>
                    <div className="bg-gray-100 animate-pulse w-4/5 h-8 my-2 "></div>
                    <div className="bg-gray-100 animate-pulse w-2/3 h-8 my-2 "></div>
                    <div className="bg-gray-100 animate-pulse w-full h-8 my-2 "></div>
                    <div className="bg-gray-100 animate-pulse w-2/3 h-8 my-2 "></div>
                    <div className="bg-gray-100 animate-pulse w-[90%] h-8 my-2 "></div>
                  </div>
                )}
                <PrintHTML text={dataTextAll?.description_seller} />
              </div>
            </Col>
          </Row>
        </Container>
        <ContactSection bg={true} />
      </section>
    </>
  );
};

export default HowItWorks;
