import { Link } from "react-router-dom";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import { Container, Input, PasswordInput } from "../../components";
import { useLOGIN } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";

function SignUp() {
  const { content } = useContextTranslate();
  const { handleSubmit, error, loading, handleChange } = useLOGIN();
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("create-account");
  };
  return (
    <div className="">
      <Container>
        <div className="w-1/2 max-lg:w-full mx-auto bg-gradient-to-t from-[#E00201] to-[#062371] p-2 rounded-3xl overflow-hidden my-10">
          <div className="bg-white flex flex-col justify-center space-y-6 border border-Blu sm:p-10 max-sm:p-4 rounded-2xl">
            <h2 className="text-3xl text-center font-bold">{content.SignUp}</h2>
            <div className="space-y-4">
              <Input
                title={content?.EnterYourName}
                type="name"
                name="name"
                onChange={handleChange}
              />
              <Input
                name="email"
                type="email"
                title={content?.Email}
                onChange={handleChange}
              />
              <PasswordInput
                title="Password"
                name="password"
                onChange={handleChange}
              />
              {/* <Input
                title="Re-type Password"
                type={!password ? "password" : "text"}
              /> */}
            </div>
            <div
              onClick={handleSubmitMain}
              className="bg-Main rounded-lg text-center py-3 text-white text-xl font-semibold transition-all hover:-translate-y-2 hover:bg-opacity-80 w-full"
            >
              {content.SignUp}
            </div>
            {loading ? <Loading /> : ""}
            <div className="text-red-600">{error}</div>
            <p className={`text-gray-700 text-center sm:text-xl`}>
              {content.YouHaveAnAccount}
              <Link
                to="/log-In"
                className="underline text-Secondary mx-1 font-bold"
              >
                {content.LogIn}
              </Link>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default SignUp;
