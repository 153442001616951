import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Container, NormalPhoto, PrintHTML } from "../../components";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import { Swiper, SwiperSlide } from "swiper/react";
import image from "../../images/Layer_x0020_1.png";
import { Col, Row } from "../../Tools/Grid-system";
function OurProduct() {
  const { data } = useFETCH(`slider-image?type=OUR_PRODUCTS`);
  const { dataTextAll, content } = useContextTranslate();
  const { data: dataNews, isLoading } = useFETCH(
    `founders?type=NEWS&local=${localStorage.getItem("language")}`
  );
  return (
    <div>
      <div className="w-full overflow-hidden h-[65vh] relative bg-gradient-to-l from-[#4B5A81] to-[#9C4759]">
        <img
          src={image}
          alt=""
          className="absolute right-0 w-1/3 bottom-0 rotate-12"
        />
        <img
          src={image}
          alt=""
          className="absolute -left-52 w-1/3 bottom-20 rotate-45"
        />
        <Row className="absolute top-1/2 -translate-y-1/2">
          <Col lg={8} className="mx-auto">
            <div className="space-y-5 text-white">
              <h2 className="text-3xl font-bold text-center">
                {content.OurProducts}
              </h2>
              <p className="text-xl font-semibold text-center mx-2">
                <PrintHTML text={dataTextAll?.our_products} />
              </p>
              <div className="flex justify-center items-center gap-5 flex-wrap">
                {data?.data.data.map((e) => (
                  <img
                    src={fileUrl + e.image}
                    alt=""
                    className="w-32 h-16 rounded-lg"
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Container>
        <h2 className="text-3xl font-bold text-center text-gray-700 my-4">
          {content.OurNews}
        </h2>
        <Swiper
          slidesPerView={1.1}
          spaceBetween={5}
          breakpoints={{
            992: {
              slidesPerView: 4.2,
            },
            678: {
              slidesPerView: 3.2,
            },
            500: {
              slidesPerView: 2.1,
            },
          }}
        >
          {isLoading ? (
            <>
              <SwiperSlide className="">
                <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                  <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                  <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto"></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="">
                <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                  <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                  <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto"></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="">
                <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                  <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                  <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto"></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="">
                <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                  <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                  <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto"></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                  <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                </div>
              </SwiperSlide>
            </>
          ) : (
            dataNews?.data.data.map((e) => (
              <SwiperSlide className="h-full">
                <NormalPhoto
                  src={fileUrl + e.image}
                  name={e.name}
                  title={e.description}
                />
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </Container>
    </div>
  );
}
export default OurProduct;
