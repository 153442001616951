import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { baseUrl, fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Button from "../Button/Button";
import { useContextTranslate } from "../Translate/ContextProvider";
import { useEffect, useState } from "react";
import PrintHTML from "../PrintHTML/PrintHTML";
import UserIcon from "../../images/User-Profile-PNG-Image.png";
import image from "../../images/Rectangle 12 (3).png";
import image2 from "../../images/pngwing 4.png";
import image3 from "../../images/_2582886903680.png";
import image4 from "../../images/_2582886903680 (1).png";

import axios from "axios";
import { Link } from "react-router-dom";
function MainSlider() {
  const { content, dataTextAll, LoadingText } = useContextTranslate();
  const { data } = useFETCH(`slider-image?type=HOME`);
  const { data: dataPropertyTypes } = useFETCH(
    `property-type?local=` + localStorage.getItem("language")
  );
  const [rooms, setRooms] = useState("");
  const [types, setTypes] = useState("");
  const [active, setActive] = useState("");
  return (
    <div className="relative h-[70vh]">
      <div className="absolute top-0 right-0 w-full bg-black/30 h-full -z-0">
        <img
          alt=""
          src={image2}
          // src={fileUrl + data?.data.data?.[0].image}
          className="object-contain h-[90%] absolute right-0"
        />
      </div>
      <div className="absolute top-0 left-0 w-full h-full -z-10">
        <img
          alt=""
          src={image}
          // src={fileUrl + data?.data.data?.[0].image}
          className="w-full h-full"
        />
        <div className="absolute bottom-0 max-lg:bottom-1/2 left-0">
          <img alt="" src={image4} className="" />
        </div>
        <div className="absolute bottom-1/2 max-lg:bottom-1/2 rotate-45 right-0">
          <img alt="" src={image3} className="" />
        </div>
      </div>
      <Container className="h-[60vh] relative z-10">
        <div className="h-[60vh] flex items-center ">
          <div className="flex-1">
            <h1 className="sm:text-5xl text-white py-2">
              {content.FindYourHome}
            </h1>
            {LoadingText && (
              <div className="bg-gray-500 animate-pulse w-2/3 h-6 my-2 "></div>
            )}
            <p className="sm:text-xl text-white py-2">
              <PrintHTML text={dataTextAll?.title_home} />
            </p>
            <div className="mx-5 flex gap-5 mt-3">
              <button
                onClick={() => setActive(false)}
                className={`${
                  active ? "" : "text-red-600"
                } bg-white/70 px-5 py-1 rounded-t-xl font-bold cursor-pointer text-lg`}
              >
                buy
              </button>
              <button
                onClick={() => setActive(true)}
                className={`${
                  active ? "text-red-600" : ""
                } bg-white/70 px-5 py-1 rounded-t-xl font-bold cursor-pointer text-lg`}
              >
                rent
              </button>
            </div>
            {active ? (
              <Col md={12} sm={8}>
                <Row className="bg-white/70 flex !pb-2 !p-4 items-center justify-center rounded-xl">
                  <Col className="!py-0 relative" lg={6} md={5}>
                    <Search />
                  </Col>
                  <Col className="!py-0" lg={2} md={3}>
                    <select
                      onChange={(e) => setTypes(e.target.value)}
                      name="property_type"
                      className=" text-center outline-none py-4 rounded-xl w-full font-semibold border border-black/50 text-black/50"
                    >
                      <option value="" hidden selected disabled>
                        {content.PropertyType}
                      </option>
                      {dataPropertyTypes?.data.data.map((e) => (
                        <option value={e.id}>{e.name}</option>
                      ))}
                    </select>
                  </Col>
                  <Col className="!py-0" md={2}>
                    <select
                      onChange={(e) => setRooms(e.target.value)}
                      name="property_type"
                      className=" text-center outline-none py-4 rounded-xl w-full font-semibold border border-black/50 text-black/50"
                    >
                      <option value="" hidden selected disabled>
                        {content.Rooms}
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">+5</option>
                    </select>
                  </Col>
                  <Col className="!py-0" md={2}>
                    <Button
                      className="py-4 !bg-Main"
                      link={
                        "/search?local=" +
                        localStorage.getItem("language") +
                        (rooms ? "&rooms=" + rooms : "") +
                        (types ? "&property_type=" + types : "")
                      }
                      title={content.Search}
                    />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col md={12} sm={8}>
                <Row className="bg-white/70 flex !pb-2 !p-4 items-center justify-center rounded-xl">
                  <Col className="!py-0 relative" lg={6} md={5}>
                    <Search />
                  </Col>
                  <Col className="!py-0" lg={2} md={3}>
                    <select
                      onChange={(e) => setTypes(e.target.value)}
                      name="property_type"
                      className=" text-center outline-none py-4 rounded-xl w-full font-semibold border border-black/50 text-black/50"
                    >
                      <option value="" hidden selected disabled>
                        {content.PropertyType}
                      </option>
                      {dataPropertyTypes?.data.data.map((e) => (
                        <option value={e.id}>{e.name}</option>
                      ))}
                    </select>
                  </Col>
                  <Col className="!py-0" md={2}>
                    <select
                      onChange={(e) => setRooms(e.target.value)}
                      name="property_type"
                      className=" text-center outline-none py-4 rounded-xl w-full font-semibold border border-black/50 text-black/50"
                    >
                      <option value="" hidden selected disabled>
                        {content.Rooms}
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">+5</option>
                    </select>
                  </Col>
                  <Col className="!py-0" md={2}>
                    <Button
                      className="py-4 !bg-Main"
                      link={
                        "/search?local=" +
                        localStorage.getItem("language") +
                        (rooms ? "&rooms=" + rooms : "") +
                        (types ? "&property_type=" + types : "")
                      }
                      title={content.Search}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
export default MainSlider;
const Search = () => {
  const { content } = useContextTranslate();
  const [checkValue, setCheckValue] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchValue, setSearchValue] = useState([]);
  const handleChangeUserName = (event) => {
    const { value } = event.target;
    setCheckValue(value);
    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      handleSubmitUserName(baseUrl + "search?local=ar&que=" + value);
    }, 1000);
    setTypingTimeout(newTypingTimeout);
  };
  const handleSubmitUserName = (url) => {
    axios
      .get(url)
      .then((response) => {
        setSearchValue(response?.data.data.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    if (!checkValue) {
      setSearchValue([]);
    }
  }, [checkValue]);
  return (
    <div>
      <input
        type="text"
        className="border border-black/50 py-4 bg-white rounded-xl"
        onChange={handleChangeUserName}
        placeholder={
          content.Search +
          " , " +
          content.Compound +
          " , " +
          content.Seller +
          " , " +
          content.location
        }
      />
      {searchValue && checkValue && (
        <div className="transition-all  md:w-[96%] mx-auto absolute bg-white rounded-md max-h-[300px] overflow-y-scroll chat  ">
          {searchValue?.map((item) => {
            if (item.type === "compound") {
              return (
                <Link
                  to={`/search?compound_id=${
                    item.items.id
                  }&local=${localStorage.getItem("language")}`}
                >
                  <div
                    key={item.items.id}
                    className="flex items-center justify-between px-5 py-2 border-b"
                  >
                    <div>
                      <h3>
                        {localStorage.getItem("language") === "en"
                          ? item.items.name.en
                          : item.items.name.ar}
                      </h3>
                      <p className="text-black/50 text-sm">
                        {localStorage.getItem("language") === "en"
                          ? item.items.address.en
                          : item.items.address.ar}
                      </p>
                    </div>
                    <div>
                      <img
                        src={fileUrl + item.items.images[0].file}
                        alt="Compound"
                        className="w-14 h-14 rounded-full"
                      />
                    </div>
                  </div>
                </Link>
              );
            } else if (item.type === "user") {
              return (
                <div key={item.type} className="flex flex-col ">
                  {item.items.map((user) => (
                    <Link
                      to={`/search?user_id=${
                        user.id
                      }&local=${localStorage.getItem("language")}`}
                    >
                      <div
                        key={user.id}
                        className="flex items-center justify-between px-5 py-2 border-b"
                      >
                        <div>
                          <h3>{user.name}</h3>
                          <p className="text-black/50 text-sm">
                            {content.Seller}
                          </p>
                        </div>
                        <div>
                          <img
                            src={UserIcon}
                            alt="Compound"
                            className="w-14 h-14 rounded-full"
                          />
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};
