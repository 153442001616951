function Input({ type, name, value, title, onChange, className }) {
  return (
    <div>
      <input
        type={type || "text"}
        name={name}
        value={value}
        placeholder={title}
        onChange={onChange}
        className={`px-5 outline-none text-lg rounded-lg py-3 w-full placeholder:text-gray-400 bg-[#E8E8E8] ${className}`}
      />
    </div>
  );
}
export default Input;
