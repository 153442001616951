import { useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { useContextTranslate } from "../Translate/ContextProvider";
import { Link } from "react-router-dom";

function Select({ onClick, name, LinkOne, optionOne, LinkTwo, optionTwo }) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { reverse } = useContextTranslate();
  return (
    <div>
      <div
        className="relative w-fit mx-auto cursor-pointer"
        onMouseOver={() => setToggleMenu(true)}
        onMouseLeave={() => setToggleMenu(false)}
      >
        <div
          className={`flex items-center justify-center gap-1 ${
            reverse ? "flex-row-reverse" : ""
          }`}
        >
          <div className="text-lg ">{name}</div>
          <RiArrowDownSLine
            color="#fff"
            size={20}
            className={`${
              toggleMenu
                ? "rotate-180 transition-all"
                : "rotate-0 transition-all"
            }`}
          />
        </div>
        <ul
          className={`${
            toggleMenu
              ? "flex-col absolute top-7 left-5 rounded-xl text-center bg-white border z-50 w-24"
              : "hidden"
          } ${reverse ? "-left-4" : "-right-4"}`}
        >
          <li className="border-b pb-1 pt-2 rounded-t-xl cursor-pointer text-black hover:bg-slate-200">
            <Link to={LinkOne || ""} onClick={onClick}>
              {optionOne}
            </Link>
          </li>
          <li className="pt-1  pb-2 rounded-b-xl cursor-pointer text-black hover:bg-slate-200">
            <Link to={LinkTwo || ""} onClick={onClick}>
              {optionTwo}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default Select;
