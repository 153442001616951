function Icon({ title, icon }) {
  return (
    <div className="flex justify-start items-center text-sm gap-2 p-3">
      <div className="flex justify-center bg-gradient-to-l from-[#203161] to-[#8B172C] rounded-full items-center w-[45px] h-[45px] ">
        <img src={icon} alt="" width={35} height={35} className="rounded-full" />
      </div>
      <h1 className="text-xl px-2">{title}</h1>
    </div>
  );
}
export default Icon;
