import { Col, Container, Row } from "../../Tools/Grid-system";
import { Input, PrintHTML } from "..";
import { Link } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../Translate/ContextProvider";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import image from "../../images/image 20.png";
import image2 from "../../images/Rectangle 56.png";
function ContactTwo({ bg, contact }) {
  const { content, dataTextAll } = useContextTranslate();
  const { data, isLoading } = useFETCH("contact-us");
  const {
    error,
    handleChangeInput,
    loading,
    handleSubmit,
    formData,
    setFormData,
  } = usePOST({});
  const mainSubmit = (e) => {
    handleSubmit("message", "/");
  };
  return (
    <Container>
      <Row>
        {isLoading ? (
          <Col md={5} className="relative">
            <Loading />
          </Col>
        ) : (
          <Col md={5} className="md:py-5 space-y-4">
            <h2 className="text-3xl text-gray-600 font-bold mb-4">
              {content.NeedExpert}
            </h2>
            <p className="text-xl ">
              <PrintHTML text={dataTextAll?.description_contact} />
            </p>
            <div>
              <h3 className="text-Secondary font-bold text-xl">
                {content.address}
              </h3>
              <p className="text-lg text-gray-600">
                <PrintHTML text={dataTextAll?.address} />
              </p>
            </div>
            <div>
              <h3 className="text-Secondary font-bold text-xl">
                {content.contacts}
              </h3>
              <p className="text-lg text-gray-600">
                {data?.data.data.phone_number}
              </p>
              <p className="text-lg text-gray-600">{data?.data.data.email}</p>
            </div>
            <div>
              <h3 className="text-Secondary font-bold text-xl">
                {content.workingHours}
              </h3>
              <p className="text-lg text-gray-600">
                {data?.data.data.working_hours}
              </p>
            </div>
          </Col>
        )}
        <Col md={7} className="py-5">
          {loading ? <Loading /> : ""}
          <div className="w-full mx-auto p-8 max-md:p-4 bg-gray-500/30 rounded-3xl space-y-3 max-md:w-[90%] overflow-hidden">
            <Row>
              <Col>
                <Input
                  onChange={handleChangeInput}
                  name="name"
                  title={content.name}
                />
              </Col>
              <Col>
                <Input
                  onChange={handleChangeInput}
                  name="preferred_location"
                  title="Preferred Location"
                />
              </Col>
              <Col>
                <PhoneInput
                  country={"eg"}
                  value={formData?.phone_number}
                  onChange={(e) =>
                    setFormData({ ...formData, phone_number: e })
                  }
                  inputClass="!w-full !h-[50px] !rounded-lg"
                />
              </Col>
              <Col>
                <textarea
                  onChange={handleChangeInput}
                  name="description"
                  placeholder={content.Message}
                  className={`bg-[#E8E8E8] px-5 outline-none text-lg rounded-lg py-3 w-full block h-[150px]`}
                />
              </Col>
            </Row>
            <div className="text-center text-red-500">{error}</div>
            <Link to="">
              <div
                onClick={mainSubmit}
                className="bg-Main rounded-lg text-center text-white text-xl font-semibold py-4 px-3 mt-4 transition-all hover:-translate-y-2 hover:bg-opacity-80"
              >
                {content.Submit}
              </div>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
export default ContactTwo;
