import { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { useContextTranslate } from "../Translate/ContextProvider";

function PasswordInput({ onChange, value, name }) {
  const [biShow, setBiShow] = useState(false);
  const { content } = useContextTranslate();
  return (
    <>
      <div className="flex relative">
        <input
          type={!biShow ? "password" : "text"}
          onChange={onChange}
          value={value}
          name={name}
          autoComplete="new-password"
          placeholder={content?.Password}
          className="px-5 outline-none text-lg rounded-lg py-3 w-full placeholder:text-gray-400 bg-[#E8E8E8]"
        />
        {biShow ? (
          <BiShow
            onClick={() => setBiShow(false)}
            size={25}
            className={`absolute ${
              localStorage.getItem("language") === "ar" ? "left-3" : "right-3"
            }  top-4 cursor-pointer`}
          />
        ) : (
          <BiHide
            onClick={() => setBiShow(true)}
            size={25}
            className={`absolute ${
              localStorage.getItem("language") === "ar" ? "left-3" : "right-3"
            }  top-4 cursor-pointer`}
          />
        )}
      </div>
    </>
  );
}
export default PasswordInput;
