import { fileUrl, useFETCH } from "../../Tools/APIs";
import {
  ContactSection,
  Container,
  NormalPhoto,
  PrintHTML,
  SecondSlider,
} from "../../components";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import OurProduct from "./OurProduct";
import { Swiper, SwiperSlide } from "swiper/react";
import image from "../../images/Group 4290.png";
function AboutUs({ name }) {
  const { dataTextAll, content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `founders?type=FOUNDER&local=${localStorage.getItem("language")}`
  );
  return (
    <div>
      <SecondSlider name={name} />
      <Container>
        <h2 className="text-3xl font-bold text-center text-gray-700 my-2">
          {content.aboutUs}
        </h2>
        <div className="relative">
          <PrintHTML text={dataTextAll?.about_us} />
          <img src={image} alt="" className="absolute top-1/2 -translate-y-1/2" />
        </div>
        <h2 className="text-3xl font-bold text-center text-gray-700 my-6">
          {content.Founders}
        </h2>
        <div>
          <Container>
            <Swiper
              slidesPerView={1.1}
              spaceBetween={5}
              breakpoints={{
                992: {
                  slidesPerView: 4.2,
                },
                678: {
                  slidesPerView: 3.2,
                },
                500: {
                  slidesPerView: 2.1,
                },
              }}
            >
              {isLoading ? (
                <>
                  <SwiperSlide className="">
                    <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                      <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                      <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto"></div>
                      <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                      <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="">
                    <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                      <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                      <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto"></div>
                      <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                      <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="">
                    <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                      <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                      <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto"></div>
                      <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                      <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="">
                    <div className="w-full h-[400px]  rounded-2xl p-5 bg-white border">
                      <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
                      <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto"></div>
                      <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                      <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto"></div>
                    </div>
                  </SwiperSlide>
                </>
              ) : (
                data?.data.data.map((e) => (
                  <SwiperSlide className="">
                    <NormalPhoto
                      src={fileUrl + e.image}
                      name={e.name}
                      title={e.description}
                    />
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </Container>
        </div>
      </Container>
      <OurProduct />
      <ContactSection bg={true} />
    </div>
  );
}
export default AboutUs;
