function SelectTwo({ data, onChange, name, title }) {
  return (
    <div>
      <select
        onChange={onChange}
        name={name}
        className=" text-center outline-none py-3 rounded-xl w-full text-black/50 font-semibold "
      >
        <option value="" disabled hidden selected className="">
          {title}
        </option>
        {data?.map((e) => (
          <option value={e.id}>{e.name}</option>
        ))}
      </select>
    </div>
  );
}
export default SelectTwo;
