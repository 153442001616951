import { useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { usePOST } from "../../Tools/APIs";

function Heart({ check, id }) {
  const [actives, setActives] = useState(false || check);
  const { handleSubmit } = usePOST({});
  return (
    <div>
      {actives ? (
        <FaHeart
          size={25}
          color="#CB3878"
          className="cursor-pointer"
          onClick={() => {
            setActives(!actives);
            handleSubmit("favorite?status=1&property_id=" + id, "", true);
          }}
        />
      ) : (
        <FaRegHeart
          size={25}
          className="cursor-pointer text-[#062371] hover:text-[#CB3878]"
          onClick={() => {
            setActives(!actives);
            handleSubmit("favorite?status=2&property_id=" + id, "", true);
          }}
        />
      )}
    </div>
  );
}
export default Heart;
