import { Link } from "react-router-dom";

function DisplaySection({ link, src, name, text, history, display }) {
  return (
    <div className="">
      {link ? (
        <Link to={link || ""}>
          <div
            className={`${
              display ? "flex-col" : ""
            } flex rounded-3xl bg-white overflow-hidden h-full border`}
          >
            <img
              src={src}
              alt=""
              className={`${
                display ? "w-full h-[250px] max-sm:!h-[150px]" : "w-1/2"
              } `}
            />
            <div className="space-y-3 p-1 mx-2 py-3 my-auto bgImg">
              <h2 className="text-Main font-bold text-xl max-sm:text-lg">
                {name}
              </h2>
              <p className="text-lg max-sm:text-sm">{text}</p>
              <p className="text-lg max-sm:text-sm">{history}</p>
            </div>
          </div>
        </Link>
      ) : (
        <>
          <div
            className={`${
              display ? "flex-col" : ""
            } flex rounded-3xl !bg-white overflow-hidden h-full border`}
          >
            <img
              src={src}
              alt=""
              className={`${
                display ? "w-full h-[250px] max-sm:!h-[170px]" : "w-1/2"
              } `}
            />
            <div className="space-y-3 p-1 mx-2 py-3 my-auto !bg-white bgImg">
              <h2 className="text-Main font-bold text-xl">{name}</h2>
              <p className="text-lg">{text}</p>
              <p className="text-lg">{history}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default DisplaySection;
