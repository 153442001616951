import { fileUrl, useFETCH, useFilter } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { Container, DisplaySection, SecondSlider } from "../../components";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import image from "../../images/Group 4290.png";

function Blogs({ name }) {
  const { searchParams } = useFilter();
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `blogs?page=${
      searchParams.get("page") ? searchParams.get("page") : 1
    }&local=${localStorage.getItem("language")}`
  );
  return (
    <div className="relative">
      <img src={image} alt="" className="absolute top-1/2 -translate-y-1/2" />
      <SecondSlider name={name} />
      <Container>
        <h2 className="text-3xl font-bold text-center">
          {content.FeaturedArticles}
        </h2>
        <Pagination
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
        >
          <Row>
            {isLoading ? (
              <>
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
              </>
            ) : (
              data?.data.data.data.map((e) => (
                <Col lg={4} sm={6}>
                  <DisplaySection
                    src={fileUrl + e.main_image}
                    name={e.name}
                    text={e.small_text}
                    history={e.date + " " + e.time}
                    display="true"
                    link={"/blogs/details/" + e.id}
                  />
                </Col>
              ))
            )}
          </Row>
        </Pagination>
      </Container>
    </div>
  );
}
export default Blogs;

const LoadingPro = () => {
  return (
    <Col lg={4} sm={6}>
      <div className="w-full h-[360px]  rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
        <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-4 "></div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 "></div>
      </div>
    </Col>
  );
};
