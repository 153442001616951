import {
  ContactSection,
  Container,
  Icon,
  SecondSlider,
} from "../../../components";
import { Col, Row } from "../../../Tools/Grid-system";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { fileUrl, useFETCH } from "../../../Tools/APIs";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import Property from "../../Property/Property";
import SectionThree from "./SectionThree";
import { useContextTranslate } from "../../../components/Translate/ContextProvider";
function Compound() {
  const { id } = useParams();
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `compounds/${id}?local=${localStorage.getItem("language")}`
  );
  let dataAll = data?.data.data;
  return (
    <div>
      <SecondSlider name={content?.Compound} />
      <Container>
        <Row className="items-center max-md:gap-5">
          <Col lg={6} md={5}>
            <Swiper
              modules={[Pagination]}
              className=""
              pagination={true}
              slidesPerView={1}
              spaceBetween={5}
            >
              {dataAll?.files.map((e) => (
                <SwiperSlide className="">
                  {e.type === "IMAGE" ? (
                    <img
                      alt=""
                      src={fileUrl + e.file}
                      className="lg:h-[380px] max-md:h-[300px] p-2 mx-auto  rounded-2xl "
                    />
                  ) : (
                    <video
                      className="lg:h-[380px] max-md:h-[300px] p-2 mx-auto rounded-2xl"
                      controls
                    >
                      <source src={fileUrl + e.file} />
                    </video>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
          <Col lg={6} md={7}>
            <h2 className="text-3xl text-Main max-sm:text-2xl font-bold my-3">
              {dataAll?.name}
              <span className="text-xl text-gray-500 font-semibold max-sm:text-lg mx-2">
                Compound
              </span>
            </h2>
            <h3 className="text-xl text-Secondary font-bold my-3">
              Price : {dataAll?.start_price}
              <span className="text-gray-500 font-semibold text-sm mx-2">
                Max Price : {dataAll?.end_price}
              </span>
            </h3>
            <h4 className="text-2xl text-Main max-sm:text-2xl font-bold my-3">
              {dataAll?.name} {dataAll?.address}
            </h4>
            <h5 className="text-2xl max-sm:text-2xl font-bold my-3">
              Amenities
            </h5>
            <Row>
              {dataAll?.attacheds.map((e) => (
                <Col xs={4}>
                  <Icon title={e.name} icon={fileUrl + e.icon} />
                </Col>
              ))}
            </Row>
            <Row>
              <Col xs={6}>
                <a href={`tel:${dataAll?.phone_number}`} target="_blank">
                  <div
                    className={`${
                      false ? "bg-green-700" : "bg-Secondary"
                    } rounded-xl text-white text-center text-xl font-semibold py-4 px-8`}
                  >
                    {content?.CallUs}
                  </div>
                </a>
              </Col>
              <Col xs={6}>
                <a
                  href={`whatsapp://send?phone=${dataAll?.whatsapp}`}
                  target="_blank"
                >
                  <div
                    className={`${
                      true ? "bg-green-700" : "bg-Secondary"
                    } rounded-xl text-white text-center text-xl font-semibold py-4 px-8`}
                  >
                    {content?.Whatsapp}
                  </div>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <h6 className="text-2xl text-Main max-sm:text-2xl font-bold my-3">
          {dataAll?.name} {dataAll?.address}
        </h6>
        <div>{dataAll?.description && parse(dataAll?.description)}</div>
        <br />
        <h3 className="font-bold text-2xl text-[#E00201] mb-5">
          {content?.paymentPlans}
        </h3>
        <Col md={4} xs={6}>
          <div className="bg-gradient-to-t from-[#E00201] to-[#062371] p-2 rounded-2xl overflow-hidden">
            <div className="bg-white rounded-lg py-5 px-4">
              <div>
                {dataAll?.payment_plans && parse(dataAll?.payment_plans)}
              </div>
            </div>
          </div>
        </Col>
      </Container>
      <SectionThree data={dataAll} id={id} name={dataAll?.name} />
      <Container>
        <Property
          ex="true"
          showSlider="true"
          filters={true}
          types={dataAll?.property_types}
        />
        <ContactSection bg={true} />
      </Container>
    </div>
  );
}
export default Compound;
