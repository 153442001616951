import { FaBath, FaBed, FaWhatsapp } from "react-icons/fa";
import { MdOutlineSocialDistance } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import Heart from "../Heart/Heart";
import Button from "../Button/Button";
import NewPropertyIcon from "../../images/NewProduct.png";
import { FiMap } from "react-icons/fi";
import { Col } from "../../Tools/Grid-system";
const ProductCard = ({
  title,
  room,
  bathroom,
  price,
  houseArea,
  NewProperty,
  image,
  address,
  whatsapp,
  phone,
  map,
  id,
  isFavorite,
}) => {
  return (
    <>
      <div
        className={
          "h-ful border h-full bg-white mx-1 relative my-2 p-2 rounded-3xl transition-all hover:-translate-y-2 shadow-md hover:shadow-xl"
        }
      >
        {NewProperty && (
          <img
            src={NewPropertyIcon}
            alt=""
            className="h-14 w-14 absolute top-5 left-5"
          />
        )}
        <img src={image} alt="" className="w-full h-[280px] max-sm:h-[240px] max-sm:object-cover rounded-3xl" />
        <div className="flex justify-between mx-3 mt-5 max-sm:mx-0 bgs">
          <div>
            <p className="text-xl max-sm:text-lg font-bold mb-1">{title}</p>
            <p className="text-lg max-sm:text-sm text-black/50">{address}</p>
            <div className="flex my-3 items-center flex-wrap gap-4">
              <div className="flex justify-center items-center gap-1">
                <FaBed
                  color="#062371"
                  className="w-[25px] h-[25px] max-sm:w-[20px] max-sm:h-[20px]"
                />
                <p className="text-sm font-bold text-Main">{room}</p>
              </div>
              <div className="flex justify-center items-center gap-2">
                <FaBath
                  color="#062371"
                  className="w-[25px] h-[25px] max-sm:w-[18px] max-sm:h-[18px]"
                />
                <p className="text-sm font-bold text-Main">{bathroom}</p>
              </div>
              <div className="flex justify-center items-center gap-2">
                <MdOutlineSocialDistance
                  color="#062371"
                  className="w-[25px] h-[25px] max-sm:w-[20px] max-sm:h-[20px]"
                />
                <p className="text-sm font-bold text-Main">{houseArea}</p>
              </div>
            </div>
            <p className="text-sm font-semibold mb-2">{price}</p>
          </div>
          <div className="my-auto space-y-2">
            <div className="flex justify-center items-center w-[35px] h-[35px] max-sm:w-[20px] max-sm:h-[20px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full">
              <Heart check={isFavorite} id={id} />
            </div>
            <div>
              <div>
                <a href={map} target="_blank">
                  <div className="flex justify-center items-center w-[35px] h-[35px] max-sm:w-[20px] max-sm:h-[20px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full cursor-pointer">
                    <FiMap
                      className="text-[#062371] hover:text-gray-500"
                      size={25}
                    />
                  </div>
                </a>
              </div>
            </div>
            <div>
              <a href={"whatsapp://send?phone=" + whatsapp}>
                <div className="flex justify-center items-center w-[35px] h-[35px] max-sm:w-[20px] max-sm:h-[20px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full">
                  <FaWhatsapp
                    className="text-[#062371] hover:text-green-400"
                    size={25}
                  />
                </div>
              </a>
            </div>
            <div>
              <a href={"tel:" + phone}>
                <div className="flex justify-center items-center w-[35px] h-[35px] max-sm:w-[20px] max-sm:h-[20px] bg-gray-400 bg-opacity-20 rounded-full shadow-xl hover:bg-slate-300 hover:rounded-full">
                  <BsTelephone
                    className="text-[#062371] hover:text-orange-400"
                    size={25}
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        <Col xs={9}>
          <Button link={"/property/details/" + id} title="See Details" className="max-sm:text-sm max-sm:py-2 max-sm:rounded-lg px-2" />
        </Col>
      </div>
    </>
  );
};
export default ProductCard;
