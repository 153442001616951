import { Link } from "react-router-dom";

function Button({ title, link, onClick, green, className }) {
  return (
    <div>
      {link ? (
        <Link to={link || ""}>
          <div
            onClick={onClick}
            className={`${
              green ? "bg-green-700" : "bg-Secondary"
            } ${className} rounded-xl text-white text-center text-xl font-semibold py-2 px-8`}
          >
            {title}
          </div>
        </Link>
      ) : (
        <div
          onClick={onClick}
          className={`${
            green ? "bg-green-700" : "bg-Secondary"
          } ${className} cursor-default rounded-xl text-white text-center text-xl font-semibold py-2 px-8`}
        >
          {title}
        </div>
      )}
    </div>
  );
}
export default Button;
