import {
  ContactSection,
  Container,
  Icon,
  SecondSlider,
} from "../../components";
import { Col, Row } from "../../Tools/Grid-system";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import Customers from "../Home/Customers";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
// import SectionThree from "./SectionThree";
const Details = ({ name }) => {
  const { id } = useParams();
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `properties/${id}?local=${localStorage.getItem("language")}`
  );
  let dataAll = data?.data.data;
  return (
    <div>
      <SecondSlider name={name} secondName={data?.data.data.name} />
      <Container>
        <Row className="items-center max-md:gap-5">
          <Col lg={6} md={5}>
            <Swiper
              modules={[Pagination]}
              className=""
              pagination={true}
              slidesPerView={1}
              spaceBetween={5}
            >
              {dataAll?.files.map((e) => (
                <SwiperSlide className="">
                  {e.type === "IMAGE" ? (
                    <img
                      alt=""
                      src={fileUrl + e.file}
                      className="lg:h-[380px] max-md:h-[300px] p-2 mx-auto  rounded-2xl "
                    />
                  ) : (
                    <video
                      className="lg:h-[380px] max-md:h-[300px] p-2 mx-auto rounded-2xl"
                      controls
                    >
                      <source src={fileUrl + e.file} />
                    </video>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
          <Col lg={6} md={7}>
            <h2 className="text-3xl text-Main max-sm:text-2xl font-bold my-6">
              {dataAll?.name}
              <span className="text-xl text-gray-500 font-semibold max-sm:text-lg mx-2">
                Compound
              </span>
            </h2>
            <h4 className="text-2xl text-Main max-sm:text-2xl font-bold my-6">
              {dataAll?.address}
            </h4>
            <h3 className="text-2xl text-Secondary max-sm:text-2xl font-bold my-6">
              Price : {dataAll?.price}
            </h3>

            <h5 className="text-2xl max-sm:text-2xl font-bold my-6">
              Amenities
            </h5>
            <Row>
              {dataAll?.attacheds.map((e) => (
                <Col xs={4}>
                  <Icon title={e.name} icon={fileUrl + e.icon} />
                </Col>
              ))}
            </Row>

            <Row>
              <Col xs={6}>
                <a href={`tel:${dataAll?.phone_number}`} target="_blank">
                  <div
                    className={`${
                      false ? "bg-green-700" : "bg-Secondary"
                    } rounded-xl text-white text-center text-xl font-semibold py-2 px-8`}
                  >
                    Call Us
                  </div>
                </a>
              </Col>
              <Col xs={6}>
                <a
                  href={`whatsapp://send?phone=${dataAll?.whatsapp}`}
                  target="_blank"
                >
                  <div
                    className={`${
                      true ? "bg-green-700" : "bg-Secondary"
                    } rounded-xl text-white text-center text-xl font-semibold py-2 px-8`}
                  >
                    Whatsapp
                  </div>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mx-auto !mt-5">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-lg leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {dataAll?.propertyType}
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-lg leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    {dataAll?.house_area} m <sup>2</sup>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr className={1 % 2 === 0 ? "bg-slate-200" : "bg-white"}>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                    {content.Rooms}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {dataAll?.rooms}
                  </td>
                </tr>
                <tr className={2 % 2 === 0 ? "bg-slate-200" : "bg-white"}>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                    {content.Bathroom}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {dataAll?.bathroom}
                  </td>
                </tr>
                <tr className={3 % 2 === 0 ? "bg-slate-200" : "bg-white"}>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                    {content.DeliveryIn}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {dataAll?.delivery_date}
                  </td>
                </tr>
                <tr className={2 % 2 === 0 ? "bg-slate-200" : "bg-white"}>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
                    {content.TypesOfFinishing}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                    {dataAll?.types_of_finishing === "1"
                      ? content.WithoutFinishing
                      : dataAll?.types_of_finishing === "2"
                      ? content.SemiFinished
                      : dataAll?.types_of_finishing === "3"
                      ? content.FullyFinished
                      : dataAll?.types_of_finishing === "4"
                      ? content.Furnished
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col></Col>
        </Row>
        <br />
        <div>{dataAll?.description && parse(dataAll?.description)}</div>
        <br />
        <h3 className="font-bold text-2xl text-[#E00201] mb-5">
          {content?.paymentPlans}
        </h3>
        <Col md={4} xs={6}>
          <div className="bg-gradient-to-t from-[#E00201] to-[#062371] p-2 rounded-2xl overflow-hidden">
            <div className="bg-white rounded-lg py-5 px-4">
              <div>
                {dataAll?.payment_plans && parse(dataAll?.payment_plans)}
              </div>
            </div>
          </div>
        </Col>
      </Container>
      {/* <SectionThree data={dataAll} id={id} /> */}
      <Container>
        <Customers
          title="Units suggested for you"
          api={`properties?local=${window.localStorage.getItem(
            "language"
          )}&property_type=${dataAll?.property_type_id}`}
        />
        <ContactSection bg={true} />
      </Container>
    </div>
  );
};

export default Details;
