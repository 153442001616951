import { Link } from "react-router-dom";

function SmallSection({
  link,
  src,
  name,
  title,
  compounds,
  propertiesAvailable,
}) {
  return (
    <div className="">
      <Link to={link}>
        <div className="overflow-hidden border rounded-[30px] text-center bg-white transition-all hover:-translate-y-2 hover:shadow-md space-y-2">
          <img
            src={src}
            alt=""
            className="w-full h-[200px] max-md:h-[150px] max-sm:h-[120px]"
          />
          <div className="bgImg">
            <h2 className="font-bold text-xl max-sm:text-lg">{name}</h2>
            <p className="text-Secondary font-semibold text-lg max-sm:text-sm">
              {title}
            </p>
            <p className="font-lg text-lg max-sm:text-sm">{compounds}</p>
            <p className="font-lg text-lg max-sm:text-sm pb-2">
              {propertiesAvailable}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default SmallSection;
