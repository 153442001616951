import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Pagination from "../../Tools/Pagination";
import { Container, SmallSection, Title } from "../../components";
const Areas = () => {
  const { data, isLoading } = useFETCH(
    `areas?local=${localStorage.getItem("language")}`
  );
  return (
    <>
      <Container>
        <Title title="Top Areas" />
        <Pagination
          pageCount={Math.ceil(
            data?.data.data.total / data?.data.data.per_page
          )}
        >
          <Row>
            {isLoading ? (
              <>
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
                <LoadingPro />
              </>
            ) : (
              data?.data.data.data.map((e) => (
                <Col lg={4} xs={6}>
                  <SmallSection
                    src={fileUrl + e.image}
                    link={
                      "/home/area/" +
                      e.id +
                      "?area_id=" +
                      e.id +
                      "&local=" +
                      localStorage.getItem("language")
                    }
                    name={e.name}
                    compounds={e.total_compounds + " compounds"}
                    propertiesAvailable={
                      e.total_properties + " properties available"
                    }
                  />
                </Col>
              ))
            )}
          </Row>
        </Pagination>
      </Container>
    </>
  );
};

export default Areas;
const LoadingPro = () => {
  return (
    <Col lg={4} xs={6}>
      <div className="w-full h-[360px]  rounded-2xl p-5 bg-white border">
        <div className="bg-slate-300 animate-pulse w-full h-[220px] rounded-2xl"></div>
        <div className="bg-slate-300 animate-pulse w-2/5 h-5 my-2 mx-auto "></div>
        <div className="bg-slate-300 animate-pulse w-2/3 h-6 my-2 mx-auto "></div>
      </div>
    </Col>
  );
};
