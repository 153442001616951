import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, SmallSection, Title } from "../../components";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Link } from "react-router-dom";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import SwiperCore from "swiper";
import "swiper/swiper-bundle.css";

// Initialize the Swiper navigation module
SwiperCore.use([Navigation]);
function TopCompound() {
  const { content } = useContextTranslate();
  const { data, isLoading } = useFETCH(
    `compounds?local=${localStorage.getItem("language")}&trending=1`
  );
  return (
    <div className="topCompound">
      <Container>
        <Row className="items-center">
          <Col xs={9}>
            <Title
              title={content.TopCompound}
              text={data?.data.data.total + " Results Available"}
            />
          </Col>
          <Col
            xs={3}
            className="text-end font-semibold text-xl underline hover:text-Main"
          >
            <Link to="/compounds">{content.viewAll}</Link>
          </Col>
        </Row>
        {isLoading && (
          <Row>
            <Col md={4} sm={6}>
              <div className="border rounded-xl px-3">
                <div className="bg-slate-300 animate-pulse w-full h-[170px] rounded-2xl my-2"></div>
                <div className="bg-slate-300 animate-pulse w-1/3 mx-auto h-6 my-2"></div>
                <div className="bg-slate-300 animate-pulse w-1/2 mx-auto h-6 my-2"></div>
              </div>
            </Col>
            <Col md={4} sm={6} className="max-lg:hidden">
              <div className="border rounded-xl px-3">
                <div className="bg-slate-300 animate-pulse w-full h-[170px] rounded-2xl my-2"></div>
                <div className="bg-slate-300 animate-pulse w-1/3 mx-auto h-6 my-2"></div>
                <div className="bg-slate-300 animate-pulse w-1/2 mx-auto h-6 my-2"></div>
              </div>
            </Col>
            <Col md={4} sm={6} className="max-md:hidden">
              <div className="border rounded-xl px-3">
                <div className="bg-slate-300 animate-pulse w-full h-[170px] rounded-2xl my-2"></div>
                <div className="bg-slate-300 animate-pulse w-1/3 mx-auto h-6 my-2"></div>
                <div className="bg-slate-300 animate-pulse w-1/2 mx-auto h-6 my-2"></div>
              </div>
            </Col>
          </Row>
        )}
        <Swiper
          modules={[Navigation]}
          navigation={true}
          slidesPerView={1}
          spaceBetween={5}
          breakpoints={{
            992: {
              slidesPerView: 3,
            },
            678: {
              slidesPerView: 3,
            },
            0: {
              slidesPerView: 2,
            },
          }}
        >
          {data?.data.data.data.map((e) => (
            <SwiperSlide className="">
              <SmallSection
                src={fileUrl + e?.main_image?.file}
                link={
                  "/home/compound/" +
                  e.id +
                  "?compound_id=" +
                  e.id +
                  "&local=" +
                  localStorage.getItem("language")
                }
                name={e.name}
                title={e.property_count + " Properties"}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
}
export default TopCompound;
