import { Col, Row } from "../../Tools/Grid-system";
import { Input, PrintHTML } from "../../components";
import { Link } from "react-router-dom";
import { useFETCH, usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import image from "../../images/image 20.png";
import image2 from "../../images/Rectangle 56.png";
function ContactSection({ bg, contact }) {
  const { content, dataTextAll } = useContextTranslate();
  const { data, isLoading } = useFETCH("contact-us");
  const {
    error,
    handleChangeInput,
    loading,
    handleSubmit,
    formData,
    setFormData,
  } = usePOST({});
  const mainSubmit = (e) => {
    handleSubmit("message", "/");
  };
  return (
    <div className=" overflow-hidden">
      <div className={bg ? "relative" : ""}>
        {contact ? (
          <div className={`w-full h-[80vh] max-md:hidden`} />
        ) : (
          <img
            src={image}
            alt=""
            className={`w-full h-[80vh] max-md:object-cover`}
          />
        )}
        <img
          src={image2}
          alt=""
          className={`${
            contact
              ? "hidden"
              : "absolute top-0 left-0 w-full h-[80vh] max-md:object-cover max-md:h-[110%]"
          } `}
        />
        <div className="w-[90%] mx-auto">
          <Row
            className={`items-center max-md:w-full z-10 ${
              contact ? "md:absolute" : "absolute"
            } top-[52%] -translate-y-1/2 w-[90%] mx-auto`}
          >
            {isLoading ? (
              <Col md={5} className="relative">
                <Loading />
              </Col>
            ) : (
              <Col md={5} className="md:py-5 space-y-4">
                {contact ? (
                  <>
                    <h2 className="text-3xl text-gray-600 font-bold mb-4">
                      {content.NeedExpert}
                    </h2>
                    <p className="text-xl ">
                      <PrintHTML text={dataTextAll?.description_contact} />
                    </p>
                    <div>
                      <h3 className="text-Secondary font-bold text-xl">
                        {content.address}
                      </h3>
                      <p className="text-lg text-gray-600">
                        <PrintHTML text={dataTextAll?.address} />
                      </p>
                    </div>
                    <div>
                      <h3 className="text-Secondary font-bold text-xl">
                        {content.contacts}
                      </h3>
                      <p className="text-lg text-gray-600">
                        {data?.data.data.phone_number}
                      </p>
                      <p className="text-lg text-gray-600">
                        {data?.data.data.email}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-Secondary font-bold text-xl">
                        {content.workingHours}
                      </h3>
                      <p className="text-lg text-gray-600">
                        {data?.data.data.working_hours}
                      </p>
                    </div>
                  </>
                ) : (
                  <div>
                    <h2 className="text-3xl max-sm:text-2xl text-gray-600 font-bold mb-4 md:text-white">
                      {content.NeedExpert}
                    </h2>
                    <p className="text-2xl max-sm:text-xl leading-10 md:text-white">
                      Fill out the form and one of our property consultants will
                      contact you.
                    </p>
                  </div>
                )}
              </Col>
            )}
            <Col md={7} className="py-5 relative">
              {loading ? <Loading /> : ""}
              <div className="w-full max-md:mr-14 mx-auto p-8 max-md:p-4 bg-white/30 rounded-3xl space-y-3 max-md:w-[90%] overflow-hidden">
                <Row>
                  <Col>
                    <Input
                      onChange={handleChangeInput}
                      name="name"
                      title={content.name}
                      className={`${contact ? "" : "bg-white"}`}
                    />
                  </Col>
                  <Col>
                    <Input
                      onChange={handleChangeInput}
                      name="preferred_location"
                      title="Preferred Location"
                      className={`${contact ? "" : "bg-white"}`}
                    />
                  </Col>
                  <Col>
                    <PhoneInput
                      country={"eg"}
                      value={formData?.phone_number}
                      onChange={(e) =>
                        setFormData({ ...formData, phone_number: e })
                      }
                      inputClass="!w-full !h-[50px] !rounded-lg"
                    />
                  </Col>
                  {/* <Col md={6}>
                  <Input
                    onChange={handleChangeInput}
                    name="location"
                    title={content.location}
                  />
                </Col> */}
                  <Col>
                    <textarea
                      onChange={handleChangeInput}
                      name="description"
                      placeholder={content.Message}
                      className={`${
                        contact ? "bg-[#E8E8E8]" : "bg-white"
                      } px-5 outline-none text-lg rounded-lg py-3 w-full block h-[150px]`}
                    />
                  </Col>
                </Row>
                <div className="text-center text-red-500">{error}</div>
                <Link to="">
                  <div
                    onClick={mainSubmit}
                    className="bg-Main rounded-lg text-center text-white text-xl font-semibold py-4 px-3 mt-4 transition-all hover:-translate-y-2 hover:bg-opacity-80"
                  >
                    {content.Submit}
                  </div>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
export default ContactSection;
