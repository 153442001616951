import { Swiper, SwiperSlide } from "swiper/react";
import { fileUrl, useFETCH } from "../../Tools/APIs";
import { Button, Container, PrintHTML, SecondSlider } from "../../components";
import { Pagination } from "swiper";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import image from "../../images/Group 4290.png";

function Design({ name }) {
  const { dataTextAll, content } = useContextTranslate();
  const { data, isLoading } = useFETCH(`slider-image?type=DESIGN`);
  return (
    <div>
      <SecondSlider name={name} />
      <Container>
        <h2 className="text-center text-3xl text-gray-700 font-bold mt-6 mb-2 ">
          {content.FeaturedArticles}
        </h2>
        <div className="relative">
          <PrintHTML text={dataTextAll?.design} />
          <img
            src={image}
            alt=""
            className="absolute top-1/2 -translate-y-1/2"
          />
        </div>
        {isLoading ? (
          <div className="loadingPro rounded-md  overflow-hidden w-full  h-[260px]">
            <div className="w-full h-[500px] max-lg:h-[400px] max-sm:h-[320px]"></div>
          </div>
        ) : (
          ""
        )}
        <Swiper
          pagination={true}
          modules={[Pagination]}
          className="mySwiper h-[75vh] !rounded-3xl max-sm:max-h-[40vh]"
          style={{ direction: "ltr" }}
        >
          {data?.data.data.map((e) => (
            <SwiperSlide className="" key={e.id}>
              <img
                alt=""
                src={fileUrl + e.image}
                className="w-full !rounded-3xl h-full my-8"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="w-fit mx-auto mt-8">
          <Button
            title={content.ContactUs + " " + content.Now}
            link="/contact-us"
          />
        </div>
      </Container>
    </div>
  );
}
export default Design;
