import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useContextTranslate } from "../../components/Translate/ContextProvider";
import { Container } from "../../components";
import { usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
function Code() {
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");
  const [value4, setValue4] = useState("");
  const v1 = useRef();
  const v2 = useRef();
  const v3 = useRef();
  const v4 = useRef();

  if (value1.length > 0) {
    v2.current.focus();
  }
  if (value2.length > 0) {
    v3.current.focus();
  }
  if (value3.length > 0) {
    v4.current.focus();
  }
  const { setFormData, handleSubmit, error, loading } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("check/verification-code", "/set-password", true);
  };
  useEffect(() => {
    setFormData({
      verification_code: `${value1}${value2}${value3}${value4}`,
      email: sessionStorage.getItem("email"),
    });
    sessionStorage.setItem("code", `${value1}${value2}${value3}${value4}`);
  }, [value1, value2, value3, value4]);
  const { content } = useContextTranslate();

  return (
    <Container>
      <div className="w-1/2 max-lg:w-full mx-auto bg-gradient-to-t from-[#E00201] to-[#062371] p-2 rounded-2xl overflow-hidden my-10">
        <div className="bg-gray-200 flex flex-col justify-center space-y-6 border border-Blu sm:p-10 max-sm:p-4 rounded-2xl">
          <h1 className="font-bold text-3xl max-xsm:font-semibold max-xsm:text-lg">
            {content.verificationCode}
          </h1>
          <div className="flex justify-center flex-col items-center h-full text-center w-2/3 mx-auto ">
            <div>
              <p>{content.WeHaveSentAVerificationCodeToYourEmail}</p>
              <p>{sessionStorage.getItem("email")}</p>
            </div>
            <div
              style={{ direction: "ltr" }}
              className="flex justify-center items-start space-x-5 max-xsm:space-x-3 my-6"
            >
              <input
                type="number"
                ref={v1}
                value={value1}
                onChange={(e) => setValue1(e.target.value.slice(0, 1))}
                className="w-14 max-xsm:w-12 max-xsm:h-12 p-1 text-white text-4xl text-center h-14 rounded-md bg-Main"
              />
              <input
                ref={v2}
                maxLength={1}
                value={value2}
                type="number"
                onChange={(e) => setValue2(e.target.value.slice(0, 1))}
                className="w-14 max-xsm:w-12 max-xsm:h-12 p-1 text-white text-4xl text-center h-14 rounded-md bg-Main"
              />
              <input
                maxLength={1}
                value={value3}
                type="number"
                ref={v3}
                onChange={(e) => setValue3(e.target.value.slice(0, 1))}
                className="w-14 max-xsm:w-12 max-xsm:h-12 p-1 text-white text-4xl text-center h-14 rounded-md bg-Main"
              />
              <input
                ref={v4}
                maxLength={1}
                value={value4}
                type="number"
                onChange={(e) => setValue4(e.target.value.slice(0, 1))}
                className="w-14 max-xsm:w-12 max-xsm:h-12 p-1 text-white text-4xl text-center h-14 rounded-md bg-Main"
              />
            </div>
            {/* <div className="text-center max-xsm:text-sm">
              <time>00:30 </time>
              {content.ResendTheCode}
            </div> */}
          </div>
          {loading ? <Loading /> : ""}
          <div className="text-red-600">{error}</div>
          <div className="w-fit mx-auto">
            <button
              className="border px-10 border-Main hover:text-white text-Main hover:bg-Main bg-white font-semibold p-3 rounded-xl"
              type="submit"
              onClick={handleSubmitMain}
            >
              {content.Verification}
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
}
export default Code;
