import { Col, Row } from "../../Tools/Grid-system";
import { Link } from "react-router-dom";
import image from "../../images/pngwing 5.png"
function Section({ end, name, text, link, onClick, title, src }) {
  return (
    <div className="bg-gradient-to-l from-[#203161] to-[#8B172C] h-[50vh] max-lg:h-[60vh] overflow-hidden">
      <Row className={`h-full`} justify={end ? "end" : "start"}>
        <Col md={6} className="relative h-full">
          <div className="w-[80%] space-y-5 absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%]">
            <p className="text-3xl text-center font-bold text-white max-sm:text-2xl">
              {name}
            </p>
            <p className="text-lg text-center text-white max-sm:text-lg">
              {text}
            </p>

            <div>
              <Link to={link || ""}>
                <div
                  onClick={onClick}
                  className="bg-white bg-opacity-60 rounded-xl text-center text-lg text-white font-semibold py-2 px-10 w-fit mx-auto"
                >
                  {title}
                </div>
              </Link>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <img src={image || src} alt="" className="object-contain bgImgC" />
        </Col>
      </Row>
    </div>
  );
}
export default Section;
