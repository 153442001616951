import image from "../../images/Rectangle 12 (2).png";
import image2 from "../../images/Rectangle 114.png";
import image3 from "../../images/_2582886903680.png";
import image4 from "../../images/_2582886903680 (1).png";

function SecondSlider({ name, secondName }) {
  return (
    <div className="relative h-[60vh]">
      <div className="absolute top-0 left-0 w-full bg-black/30 h-full -z-0"></div>
      <div className="absolute top-0 left-0 w-full h-full -z-10">
        <img alt="" src={image} className="w-full h-full" />
      </div>
      <div className="absolute top-0 left-0 w-full h-full -z-10">
        <img alt="" src={image2} className="w-full h-full" />
      </div>
      <div className="absolute bottom-0 right-0 -z-10">
        <img alt="" src={image3} className="" />
      </div>
      <div className="absolute bottom-0 max-lg:bottom-1/2 left-0 -z-10">
        <img alt="" src={image4} className="" />
      </div>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
        <p className="font-bold text-3xl text-white">{name}</p>
        {secondName && (
          <p className="font-semibold text-xl text-white mt-8">{`${name} / ${secondName}`}</p>
        )}
      </div>
    </div>
  );
}
export default SecondSlider;
