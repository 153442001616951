import { Row } from "../../Tools/Grid-system";
function Footer2() {
  return (
    <div className="bg-Main text-white w-full text-center max-sm:text-sm overflow-hidden">
      <div className="w-[90%] mx-auto py-1">
        <Row justify="center" className="items-center max-lg:gap-3">
          <div className="flex flex-col justify-center gap-1 text-sm text-center">
            <p>
              All Rights Reserved For AQAR PLUS company @
              {new Date().getFullYear()}
            </p>
          </div>
        </Row>
      </div>
    </div>
  );
}
export default Footer2;
