import { ContactSection, MainSlider, Section } from "../../components";
import Customers from "./Customers";
import TopCompound from "./TopCompound";
import TopAreas from "./TopAreas";
function Home() {
  return (
    <>
      <MainSlider />
      <TopCompound />
      <Section
        name="Explore new project"
        text="All you want to know about trending and upcoming projects"
        title="View All"
        link="/coming-soon-items"
      />
      <TopAreas />
      <Customers />
      <ContactSection bg={true} />
    </>
  );
}
export default Home;
